<template>
  <div class="pb grid_width">

    <div class="headerow">  
 
      <h1 class="ui header">
        {{ $t('diaries_view_title') }}
      </h1>

      <div v-if="useAuth().isAuth()">
        <div class="btn" v-if="isFollow == 1" @click="presetSave">
          {{ $t('universal_button_follow') }}
        </div> 
        <div class="btn" v-if="isFollow == 2" @click="presetRemove">
          {{ $t('universal_button_unfollow') }}
        </div>
      </div>
 
      <GeneralSearchKeyword
        placeholder="Search Breeders & Nutrients"
        @choose="chooseTagKeyword"
        />

      <ExplorePresets v-if="useAuth().isAuth() || presetName || presetLink"
        :preset-name="presetName"
        :preset-link="presetLink"
        />
      
      <GeneralSearchTags  
        class="filters"
        :tags="tags" 
        :tagsselected="tagsSelected" 
        :tagsdefault="tagsDefault" 
        :tagscount="exploreData?.items_tags"
        :tag-as-link="true"
        :tag-link="'/explore'"
        :modify-path="true"
        :is-show-counts="true"
        @choose="chooseTags"
        @unchoose="unchooseTags"
        ref="elSearchTags"
        />
 
    </div>




    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="[
          {id:'update', name: $t('universal_sort_last_update')},      
          {id:'score', name: $t('universal_sort_grow_score')},      
          {id:'likes', name: $t('universal_sort_likes2')},      
          {id:'create', name: $t('universal_sort_creation_date')},      
        ]"
        />
    </div>

      
    <SkExploreDiaries
      v-if="isLoading && !exploreData?.items?.length"
      />
      
    <div v-else-if="exploreData?.items?.length" class="report_boxs reports_grid">      
      <GeneralDiary       
        v-for="(opt,ind) in exploreData.items"    
        :key="ind"    
        :data="opt"      
        />          
      <client-only>        
        <InfinityScroll 
          v-if="exploreData?.items?.length >= limit"
          :is-loading="isLoading"
          ref="elInfinityScroll"/>
      </client-only>
    </div>

    <UiEmpty
      v-else-if="!exploreData?.items?.length"
      />

    <SkExploreDiaries
      v-else
      />
  

  </div>
</template>



<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const route = useRoute();
const router = useRouter();
const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const {$api, $adv, $tagsUtil, $ga, $popup, $patcher} = useNuxtApp()
const { t } = useI18n()
const elInfinityScroll = ref(null)
const elSearchTags = ref(null)
const sort = ref('update')
const tags = ref(JSON.parse(JSON.stringify($tagsUtil.tagsExplore)))

const presetName = ref('')
const presetLink = ref('')
const tagsSelected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))
const tagsDefault = ref($tagsUtil.tagsExplore)

const isFollow = ref(0)

const clearPagination = function(){     
  start.value = 0;  
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}

const chooseTagKeyword = function(tag){     
  if(tagsSelected.value.indexOf('al') != -1)
    tagsSelected.value = [];
  // tags.value[tag.tag] = tag;
  tagsSelected.value.push(tag.tag)  
  clearPagination();        
  $ga.searchFullTextExplore($tagsUtil.getTagsKeyword(tags.value, tagsSelected.value));
}

const unchooseTags = function(selected){  
  tagsSelected.value = tagsSelected.value.filter((item) => item != selected);
  clearPagination();
}

const chooseTags = function(selected){          
  tagsSelected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', $tagsUtil.getTagsSimple(tags.value, tagsSelected.value));
}
 

const togglePreset = function(){
  isFollow.value = !tagsSelected.value || tagsSelected.value[0] == 'al' ? 0 : 1;
}

const loadData = async function() {
  
  const response = await $api.getExplore({
    start: start.value,
    limit: limit.value,     
    sortable: sort.value,
    tagsall: 'al', 
    tags: tagsSelected.value.join(',')
  })  
  return response;  
}

const presetSave = async function() {
 
  var name = elSearchTags.value.getActiveTagsName()
  var url = '/explore?tags=' + tagsSelected.value.join(',');
  if(name && url){
    $api.postGrowerPresetsExplore(useAuth().getId(), {
      name: name,
      url: url
    })
    .then((res) => {
      //  var response = res.value;
      //  if(response && response.data && response.data.id){
      //   exploreData.value.items_preset.push(response.data);
      //   $popup.success('Saved');
      // }
      $popup.success('Saved');
    })
    .catch(function (error) {
      $popup.success('Something went wrong. Please contact tech support');
    });
    
    /*const response = await $api.postSearchPreset({
      name: name,
      url: url
    });
    
    if(response){
      var res = response.value;
      if(res && res.data && res.data.id){
        exploreData.value.items_preset.push(res.data);
      }
      //isFollow.value = 2;
    }*/
  }
}

const { pending: isLoading,  data: exploreData } = await useLazyAsyncData('exploreData', async () => await loadData())


watch( () => route.query, (new_route, old_route) => {
  // week_id.value = new_week_id;
  console.log('new_route.params'); 

  tagsSelected.value = route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : ['al']);
  // useHead(useNuxtApp().$head.getExplore( ($tagsUtil.getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));
}, {deep: true})


// watchArray([route], async ([new_route], added, removed) => {
//   console.log('watcher route');

//   tagsSelected.value = new_route.params['tag']?.length ? new_route.params['tag'] : ( new_route.query?.tags ? new_route.query.tags.split(",") : ['al']);
//   isFollow.value = 0;
  
// useHead(useNuxtApp().$head.getExplore( ($tagsUtil.getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));

// }, {deep: true})


watchArray([start, sort, tagsSelected], async ([new_start, new_sort, new_tagsSelected], added, removed) => {
  console.log('watcher');

  if(new_start == 0){
    exploreData.value.items = [];
    isEnded.value = false;
  } 


  $patcher.change('/explore', {
    tags: new_tagsSelected,
    sort: new_sort,
  });
  
  isLoading.value = true;
  var dt = await loadData();

  if(dt.length == 0)
    isEnded.value = true;  

  exploreData.value.items = [...exploreData.value.items, ...dt.items]
  if(dt.items_tags?.length)
    exploreData.value.items_tags = dt.items_tags;
  //exploreData.value.items_preset = [...exploreData.value.items_preset, ...dt.items_preset]
  isLoading.value = false;

  
  // $adv.apply();

  // useHead(useNuxtApp().$head.getExplore( ($tagsUtil.getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));
  
  togglePreset();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!isLoading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)



useHead(useNuxtApp().$head.getExplore( ($tagsUtil.getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));

// onMounted(() => {
//   console.log('onmounted head');
//   useHead(useNuxtApp().$head.getExplore( ($tagsUtil.getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));
// })

// watch( () => exploreData, (new_route, old_route) => {
//   console.log('watch exploreData');
//   useHead(useNuxtApp().$head.getExplore( ($tagsUtil.getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));
// });


onBeforeUnmount(() => {
  exploreData.value = null;
})
// $adv.run();

</script>

<style scoped>

.header_row{
  position: relative;
}
.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.sortb{
  margin-left: auto;
  align-self: baseline;
  margin-right: 0;
  display: flex;
  justify-self: flex-end;
  width: fit-content;
}
.filter_icon{
  display: none;
}
.filters{
  display: flex;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.headerow > .search{
  margin-left: auto;
}
@media (max-width: 768px) {
  .add_filter_box{
    width: 100%;
  }
.headerow > .search_preset{
  margin-left: auto;
}
  .add_filter_box .search{
    width: 100%;
  }
  .filter_icon{
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1rem;
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  } 

  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
   
}

</style>
